// Copyright (C) 2024 Alexandre Amat
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <http://www.gnu.org/licenses/>.

import { Table } from "semantic-ui-react";

export default function TableHeader(props: {
  headers: string[];
  actions: number;
}) {
  return (
    <Table.Header>
      <Table.Row>
        {props.headers.map((header, index) => (
          <Table.HeaderCell key={index}>{header}</Table.HeaderCell>
        ))}
        {props.actions !== 0 && (
          <Table.HeaderCell key="actions" colSpan={props.actions}>
            Actions
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
}
