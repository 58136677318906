// Copyright (C) 2024 Alexandre Amat
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <http://www.gnu.org/licenses/>.

import { format } from "date-fns";
import { CSSProperties } from "react";
import { Placeholder } from "semantic-ui-react";

export default function FormattedTimestamp(props: {
  timestamp?: Date;
  loading?: boolean;
  style?: CSSProperties;
}) {
  if (props.loading)
    return (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line style={props.style} />
        </Placeholder.Header>
      </Placeholder>
    );

  return (
    <p style={props.style}>
      {props.timestamp && format(props.timestamp, " yyyy MMMM d")}
    </p>
  );
}
