// Copyright (C) 2024 Alexandre Amat
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <http://www.gnu.org/licenses/>.

import { createSlice } from "@reduxjs/toolkit";

const isMobile = () => window.matchMedia("(max-width: 768px)").matches;

const slice = createSlice({
  name: "app",
  initialState: {
    isSideBarOpen: false,
    isMobile: isMobile(),
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    windowResize: (state) => {
      state.isMobile = isMobile();
    },
  },
});

export const { windowResize, toggleSidebar } = slice.actions;

export default slice.reducer;
